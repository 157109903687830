<template>
  <div class="state-subview">

  </div>
  <div class="general-information-subview">
    <Section class="section--border-bottom bg-gray-lightestest">
      <Container>
        <Timeline :index="step" :total="states.length" :lastStepToRight="true" :stepsData="states" />
      </Container>
    </Section>
    <Section v-if="fetched && (noOrder || studiedVariable.length <= 0 || !essai?.plan)">
      <Container>
        <Alert :hasCloseBtn="false" type="warning">
          <p v-if="!essai?.plan">L'essai actuel ne dispose pas de plan.<br /> Pour consulter le calendrier, veuillez
            générer un plan.</p>
          <p v-if="noOrder">Aucun ordre de saisie n’est renseigné.</p>
          <p v-if="studiedVariable.length <= 0">Aucune variable n'a été ajouté.</p>
        </Alert>
      </Container>
    </Section>

    <Section>
      <Container>
        <template v-if="fetched && !noOrder && studiedVariable.length > 0 && essai?.plan">
          <NotationCard v-if="fetched" :reservation="reservation" :isOwner="owner" :isInDashboard="false" childe />
        </template>
      </Container>
    </Section>

    <Section v-if="fetched && error">
      <Container>
        <Alert :hasCloseBtn="false" type="error">
          {{ error }}
        </Alert>
      </Container>
    </Section>

  </div>
  <ProgressModal v-if="!error" title="Chargement de la reservation" text="Chargement en cours" :displayCode="false" />
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Timeline from '@/components/layout/Timeline.vue'
import NotationCard from '@/components/base/NotationCard.vue'
import Alert from '@/components/base/Alert.vue'
import ProgressModal from '@/views/componentsViews/notation/ProgressModal.vue'

export default {
  name: 'NotationStateSubview',
  components: {
    Alert,
    Section,
    Container,
    NotationCard,
    ProgressModal,
    Timeline,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    essai: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const statesUids = [
      'ENPREPARATION',
      'RESERVE',
      'ENCOURS',
      'ATTENTESYNCHRO',
      'PARTIELLEMENTREALISE',
      'REALISE',
    ]

    return {
      step: 0,
      states: [],
      notation: {},
      frames: [],
      reservation: {},
      reservations: [],
      reservationStatus: this.$store.state.app.reservationStatus,
      stades: this.$store.state.app.stades,
      studiedVariable: [],
      owner: false,
      statesUids,
      noOrder: false,
      fetched: false,
      editable: this.isEditable,
      progress: { total: 12, current: 0 },
      error: null,
    }
  },

  mounted() {
    this.getDataNotationState('root')
    this.emitter.on('notation-refresh', this.getDataNotationState)
  },

  methods: {
    async getDataNotationState() {
      try {
        this.progress.current = 0
        this.emitter.emit('progress-modal-data', this.progress)
        this.emitter.emit('progress-modal-open')

        if (this.$route.params.id) {
          this.progress.current = 1
          this.emitter.emit('progress-modal-progress', this.progress)

          // fetch de la notation pour vérifier qu'elle est bien paramétré
          const notationResponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`)
          this.notation = notationResponse.data.pop()
          // si pas de d'odre ou pas de plan on coupe
          if (
            (!this.essai?.plan) || (
              this.notation.saisie_objet.uid !== 'MODALITE'
              && (
                this.notation.saisie_ordre === null
                || (this.notation.saisie_ordre.uid === 'MANUEL'
                  && this.notation.chemin_personnalise === null)
              )
            )) {
            this.noOrder = true
            this.emitter.emit('progress-modal-close')
            this.fetched = true
            return false
          }
          this.progress.current = 2
          this.emitter.emit('progress-modal-progress', this.progress)

          // Fetch les variables
          const variablesResponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/variable_etudiee`, { limit: 0 })
          this.studiedVariable = variablesResponse.data
          this.progress.current = 3
          this.emitter.emit('progress-modal-progress', this.progress)

          this.progress.current = 4
          this.emitter.emit('progress-modal-progress', this.progress)

          // Fetch des reservations
          const reservationResponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/reservation`, { limit: 0, sort: 'id.ASC' })
          this.reservations = reservationResponse.data
          if (this.reservations.length > 0) {
            this.reservation = this.reservations.pop()
          } else {
            this.reservation = {
              id: null,
              etat: {
                uid: 'ENPREPARATION',
                designation: 'En préparation',
              },
              utilisateur: this.$store.state.auth.user.data,
            }
          }
          this.progress.current = 5
          this.emitter.emit('progress-modal-progress', this.progress)

          this.reservation.essai = this.essai
          this.reservation.notation = this.notation

          this.progress.current += 1
          this.emitter.emit('progress-modal-progress', this.progress)

          this.states = []
          this.statesUids.forEach((element) => {
            const state = this.reservationStatus.find(
              (item) => item.uid === element,
            )
            if (state) {
              this.states.push(state.designation)
            }
          })

          this.step = this.statesUids.findIndex(
            (v) => v === this.reservation.etat.uid,
          )
          this.progress.current += 1
          this.emitter.emit('progress-modal-progress', this.progress)

          this.progress.current += 1
          this.emitter.emit('progress-modal-progress', this.progress)

          this.progress.current += 1
          this.emitter.emit('progress-modal-progress', this.progress)

          if (this.reservation?.etat?.uid === 'ENPREPARATION') {
            this.editable = true
          } else {
            this.editable = false
          }

          this.fetched = true
          this.progress.current = 11
          this.emitter.emit('progress-modal-progress', this.progress)
        }

        this.emitter.emit('progress-modal-close')
        return true
      } catch (error) {
        this.emitter.emit('progress-modal-close')
        console.error(error)
        this.error = error
        this.fetched = true
        return true
      }
    },

  },
}
</script>
